
@keyframes pulse {
    0% {
        opacity: 1;
        color: 'red';
    }
    50% {
        opacity: 0.5;
        color: 'white';
    }
    100% {
        opacity: 1;
        color: 'red';
    }
}